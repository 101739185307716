.t3 {
  width: 100%;
  background: rgb(243, 245, 250);

  overflow: hidden;
}
.t3_1 {
  width: 1200px;
  margin: 70px auto 0 auto;
}
.t3_2 {
  width: 380px;
  height: 87px;
  background: url("//static.taocheche.com/taoche/pc/assets/images/bg4.png")
    no-repeat left top/380px 87px;
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #1e293b;
  letter-spacing: 0;
  font-weight: 600;
}

.txt3div2 {
  width: 1200px;
  margin: 30px auto 0 auto;
  height: 460px;
}
.swiperdiv {
  width: 100%;
  height: 440px;
  position: relative;
}

.sfq_border2 {
  width: 54px;
  height: 4px;
  margin: 30px auto 0;
  background: #ffe900;
}

.swiper {
  width: 100%;
  height: 440px;

  .swiperSlide {
    position: relative;
    width: 277px;
    // height: 440px;
    background-color: #fff;
    overflow: hidden;
    cursor: pointer;
    .img {
      transition: all 0.2s ease-in;
    }

    .content {
      position: absolute;
      background-color: rgba(0, 47, 167, 0.85);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      overflow: hidden;
      height: 0;
      width: 0;
      line-height: 0;
      font-size: 0;
      padding: 0;
      transition: all 0.2s ease-in;

      .content2 {
        // height: 370px;
        // width: 277px;
        overflow: hidden;
      }
      .line1 {
        // width: 30px;
        // height: 28px;
        // transform: translateY(-33px);
        font-family: HelveticaNeue-Bold;
        font-size: 65px;
        line-height: 0px;
        color: #ffe900;
        letter-spacing: 0;
        font-weight: 700;
        padding-top: 30px;
        padding-bottom: 14px;
        // margin: 30px 30px 0;

        // margin-bottom: 10px;
        // margin-left: 33px;
      }
      .line2 {
        font-family: PingFangSC-Medium;
        font-size: 22px;
        line-height: 22px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 500;
        user-select: none;
        // margin: 0 30px;
      }
      .line3 {
        font-size: 0;
        line-height: 0;
        height: 1px;
        background: #ffffff;
        width: 100%;
        // margin: 14px 30px 0;
        user-select: none;
        margin: 19px 0;
      }
      .line4 {
        // margin-top: 14px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: white;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;
        user-select: none;
        // margin: 0 30px;
      }
    }
    &:hover {
      .content {
        height: 370px;
        width: 277px;
        padding: 24px;
        padding-top: 46px;
      }
      .bottom {
        opacity: 0;
      }

      .img {
        height: 370px;
        width: 464px;
      }
    }
    .top {
      width: 277px;
      height: 370px;
      overflow: hidden;
    }
    .bottom {
      border-top: 6px solid #ffe900;
      padding: 30px;
      height: 180px;
      position: absolute;
      bottom: 0;
      background-color: white;
      opacity: 1;

      .title {
        font-family: PingFangSC-Medium;
        font-size: 22px;
        color: #1e293b;
        letter-spacing: 0;
        font-weight: 500;
        line-height: 30px;
        padding-bottom: 14px;
        border-bottom: 1px solid #93a3b8;
      }
      .txt {
        margin-top: 14px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #93a3b8;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        user-select: none;
      }
    }
  }
}

.navigation {
  position: absolute;
  bottom: 10px;
  width: 200px;
  display: flex;
  left: 50%;
  margin-left: -100px;
  // border-bottom:1px solid  #93A3B8;
  box-shadow: 0 1px 2px -1px #93a3b8;

  .active {
    height: 8px;
    width: 33px;
    background-color: #ffe900;
    transform: translateX(0);
  }
}
