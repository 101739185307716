.margin70 {
  margin-top: 70px;

}
.shoutu {
  position: relative;

  .taoCarsVideo {
    width: 100%;

    margin-top: 60px;
    display: inline-block;
    margin-bottom: -70px;
    // margin-bottom: 70px;
    // video {
    //   aspect-ratio: 3.2;
    // }
  }
  .myVideo {
    width: 100%;
    // height: 100%;
    // object-fit: cover;
  }
  .shoututext {
    font-size: 100px;
    color: #ffe900;
    position: absolute;
    top: 108px;
    left: 150px;
    .text1 {
      display: block;
      height: 105px;
    }

    .textA {
      width: 210px;
      height: 116px;
      font-family: SourceHanSansCN-Bold;
      font-size: 80px;
      color: #ffe900;
      // letter-spacing: 5px;
      line-height: 116px;
      text-shadow: 0 2px 12px rgba(30, 41, 59, 0.37);
      font-weight: 700;
    }
    // .textStyle{
    //   margin-left: -12px;
    // }
    .textB {
      width: 262px;
      height: 116px;
      font-family: SourceHanSansCN-Medium;
      font-size: 70px;
      color: #ffffff;
      letter-spacing: 5px;
      line-height: 116px;
      text-shadow: 0 2px 12px rgba(30, 41, 59, 0.37);
      font-weight: 500;
    }
  }
}

// //全屏按钮
// video::-webkit-media-controls-fullscreen-button {
//   display: none;
// }
// //播放按钮
// video::-webkit-media-controls-play-button {
//   display: none;
// }
// //进度条
// video::-webkit-media-controls-timeline {
//   display: none;
// }
// //观看的当前时间
// video::-webkit-media-controls-current-time-display {
//   display: none;
// }
// //剩余时间
// video::-webkit-media-controls-time-remaining-display {
//   display: none;
// }
// //音量按钮
// video::-webkit-media-controls-mute-button {
//   display: none;
// }
// video::-webkit-media-controls-toggle-closed-captions-button {
//   display: none;
// }
// //音量的控制条
// video::-webkit-media-controls-volume-slider {
//   display: none;
// }
// //所有控件
// video::-webkit-media-controls-enclosure {
//   display: none;
// }
// video::-webkit-media-controls {
//   /*不显示时间条*/
//   object-fit: fill; /*设置预览图铺满*/
//   display: none !important;
// }

.bg2{
  background: #f2f5fa;

}
.t1 {
  width: 100%;
  height: 510px;
  background: #f2f5fa;
  overflow: hidden;
}
.t2 {
  width: 100%;
  height: 520px;
  background: white;
  overflow: hidden;
}
.btn {
  background-color: #ffe900;
  color: #1e293b;
  width: 250px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin: auto;
  margin-bottom: 80px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.height2 {
  height: 550px;
}
.float {
  width: 191px;
  height: 80px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 999;
  cursor: pointer;
}

.t1_1 {
  width: 1200px;
  margin: 70px auto 0 auto;
  display: flex;
  justify-content: space-between;

  .left {
    width: 500px;
    background: url("//static.taocheche.com/taoche/pc/assets/images/bg1.png")
      no-repeat left top/240px 85px;
  }
  .left2 {
    width: 500px;
    background: url("//static.taocheche.com/taoche/pc/assets/images/bg2.png")
      no-repeat left top 8px/240px 85px;
  }
  .txtDiv {
    width: 400px;
    margin-top: 40px;
    margin-left: 80px;
    .txt1 {
      margin-left: 20px;
      height: 56px;
      font-family: PingFangSC-Semibold;
      font-size: 40px;
      color: #1e293b;
      letter-spacing: 0;
      font-weight: 600;
    }
    .txt2div {
      margin-left: 17px;
    }
    .txt2 {
      margin-top: 17px;
      font-family: PingFangSC-Medium;
      font-size: 24px;
      color: #1e293b;
      letter-spacing: 0;
      font-weight: 500;
      position: relative;
      padding-left: 20px;
    }
    .txt2::before {
      content: "";
      width: 8px;
      height: 8px;
      background: #002fa7;
      top: 16px;
      left: 0;
      position: absolute;
    }
    .txt2sub {
      width: 340px;

      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #93a3b8;
      letter-spacing: 0;
      text-align: justify;
      line-height: 30px;
      font-weight: 400;
    }
    .sfq_border {
      width: 54px;
      height: 4px;
      margin-top: 20px;
      background: #ffe900;
    }
  }
  .right {
    width: 650px;
    height: 420px;
  }
  .imgBottom {
    border-bottom: 6px solid #ffe900;
  }
}

.t2_1 {
  width: 990px;
  margin: 70px auto 0 auto;
}
.t2_2 {
  width: 284px;
  height: 87px;
  background: url("//static.taocheche.com/taoche/pc/assets/images/bg3.png")
    no-repeat left top/275px 87px;
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #1e293b;
  letter-spacing: 0;
  font-weight: 600;
}
.sfq_border2 {
  width: 54px;
  height: 4px;
  margin: 30px auto 0;
  background: #ffe900;
}
.txt2div2 {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  .txt2 {
    padding-top: 190px;
    height: 33px;
    font-family: PingFangSC-Semibold;
    font-size: 24px;
    color: #1e293b;
    letter-spacing: 0;
    font-weight: 600;
    text-align: center;
  }

  .txt21 {
    height: 180px;
    width: 250px;
    background: url("//static.taocheche.com/taoche/pc/assets/images/top5-1.webp")
      no-repeat center/250px 180px;
  }
  .txt22 {
    width: 250px;
    height: 180px;

    background: url("//static.taocheche.com/taoche/pc/assets/images/top5-2.webp")
      no-repeat center/250px 180px;
  }
  .txt23 {
    width: 250px;
    height: 180px;
    background: url("//static.taocheche.com/taoche/pc/assets/images/top5-3.webp")
      no-repeat center/250px 180px;
  }
  
  .txt2sub {
    height: 30px;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #93a3b8;
    letter-spacing: 0;
    text-align: center;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
  }
}
