.ts2 {
  
  background: url("//static.taocheche.com/taoche/pc/assets/images/top6bg.webp?v=23")
    no-repeat center/1920px 487px #002fa7;
}
.ts3{
  width: 100%;
  height: 487px;
  position: relative;
}

.t21 {
  width: 1100px;
  left: 50%;
  margin-left: -550px;

  height: 100%;
  display: flex;
  justify-content: flex-start;

  position: absolute;
  z-index: 2;

  bottom: 0;
}

.t22 {
  width: 487px;
  height: 100%;
  background: url("//static.taocheche.com/taoche/pc/assets/images/homeDown2_20250310.png?v=3")
    no-repeat bottom left/auto 85%;
  display: table-cell;
}

.t23 {
  width: 500px;
  margin: 140px 0 0 100px;
}

.t24 {
  display: flex;
}
.t241 {
  display: flex;
  margin-top: 50px;
}
.t25 {
  width: 120px;
  height: 120px;
  background: url("//static.taocheche.com/taoche/pc/assets/images/PCFooterDownNew.png")
    no-repeat center/120px;
}

.t255 {
  width: 120px;
  height: 120px;
  background: url("//static.taocheche.com/taoche/pc/assets/images/PCApplets.webp")
    no-repeat center/120px;
  border-radius: 60px;
}
.t26 {
  margin-left: 20px;
  .t27 {
    font-family: PingFangSC-Semibold;
    font-size: 30px;
    color: #ffffff;
    letter-spacing: 0;

    font-weight: 600;
    padding-top: 10px;
    text-align: left;
  }
  .t28 {
    padding-top: 10px;

    font-family: PingFangSC-Regular;
    font-size: 24px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 400;
  }
}
