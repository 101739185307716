.shoufengqin {
  margin: 70px auto 0 auto;
  width: 1200px;
  height: 500px;
  overflow: hidden;
  ul {
    display: flex;
    padding: 0;
  }
  ul li {
    list-style: none;

    width: 160px;
    height: 500px;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1), z-index 0s 0.12s;
  }
}

.cur {
  width: 560px;
  height: 500px;
}

.sfq_bg1 {
  background: url("//static.taocheche.com/taoche/pc/assets/images/store1.webp")
    no-repeat left top/ cover;
}

.sfq_bg2 {
  background: url("//static.taocheche.com/taoche/pc/assets/images/shoufengqin/top2-110000-01.webp")
    no-repeat left top/cover;
}

.sfq_bg3 {
  background: url("//static.taocheche.com/taoche/pc/assets/images/shoufengqin/top2-210100.webp")
    no-repeat left top/cover;
}

.sfq_bg4 {
  background: url("//static.taocheche.com/taoche/pc/assets/images/shoufengqin/top2-371300.webp")
    no-repeat left top/cover;
}

.sfq_bg5 {
  background: url("//static.taocheche.com/taoche/pc/assets/images/shoufengqin/top2-650100.webp")
    no-repeat left top/cover;
}

.sfq_sfqItem {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.sfq_mengceng {
  position: absolute;
  background: rgba(0, 47, 167, 0.85);
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.sfq_mengceng2 {
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
}

.sfq_text {
  position: relative;
  width: 400px;
  margin: 126px auto 0 auto;
  z-index: 2;
}

.sfq_text1 {
  font-family: PingFangSC-Semibold;
  font-size: 40px;
  padding: 5px 0;
  line-height: 46px;
  color: #f2f5fa;
  letter-spacing: 0;
  font-weight: 600;
  margin-left: 20px;
}
.sfq_text22 {
  padding: 126px 15px 0;
  font-family: PingFangSC-Semibold;
  font-size: 40px;

  line-height: 46px;
  color: #f2f5fa;
  letter-spacing: 0;
  font-weight: 600;

  margin: 0 auto;
  writing-mode: vertical-lr;
  letter-spacing: 10px;

  position: relative;
  z-index: 2;
}
.sfq_border {
  width: 54px;
  height: 4px;
  background: #ffe900;
  margin-top: 16px;
}
.sfq_text2 {
  height: 110px;
  font-family: PingFangSC-Regular;
  font-size: 24px;
  color: #f2f5fa;
  letter-spacing: 0;
  font-weight: 400;
  margin-left: 20px;
  margin-top: 14px;
}

.sfq_button {
  width: 84px;
  height: 36px;
  border: 1px solid rgba(255, 255, 255, 1);
  color: #fff;
  margin-left: 20px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  display: block;
}

#cardArea {
  margin: 0 auto;
  width: 1200px;
  height: 500px;
  position: relative;
}

#cardArea li {
  z-index: 4;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1), z-index 0s 0.12s;
  position: relative;
  float: left;
  width: 160;
  height: 500px;
  background-color: transparent;
}
#cardArea li + li {
  margin-left: -1px;
}
#cardArea li .card {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1), z-index 0s 0.12s;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fff;
}
#cardArea li .card .card-content {
  width: 100%;
  line-height: 2;
}
#cardArea li .cardContent1 {
  transition: all 0.12s cubic-bezier(0.4, 0, 0.2, 1) 0.18s;
  z-index: 13;
  opacity: 1;
  position: absolute;
  filter: alpha(opacity=100);
}
#cardArea li .cardContent2 {
  transition: all 0.12s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  z-index: 14;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  line-height: 2;
}

#cardArea .active {
  z-index: 6;

  width: 586px;
}

#cardArea .active .cardContent1 {
  transition: all 0.12s cubic-bezier(0.4, 0, 0.2, 1) 0.18s;
  z-index: 13;
  opacity: 0;
  filter: alpha(opacity=0);
}

#cardArea .active .cardContent2 {
  transition: all 0.12s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  z-index: 14;
  opacity: 100;
  filter: alpha(opacity=100);
}

.zuobiao {
  position: relative;
  z-index: 3;
  height: 20px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #f2f5fa;
  letter-spacing: 0;
  font-weight: 400;
  text-align: center;
  background: url("//static.taocheche.com/taoche/pc/assets/images/location.png")
    no-repeat center top/ 20px;
  padding-top: 30px;
  margin-top: 380px;

  opacity: 1;
}

.active .zuobiao {
  opacity: 1;
}

.zuobiao2 {
  position: relative;
  z-index: 3;
  text-align: left;
  padding-left: 20px;
  display: table;
  height: 20px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #f2f5fa;
  letter-spacing: 0;
  font-weight: 400;

  background: url("//static.taocheche.com/taoche/pc/assets/images/location.png")
    no-repeat center top/ 20px;
  padding-top: 30px;
  margin-top: 380px;

  opacity: 1;
}
.active .zuobiao2 {
  opacity: 1;
}
